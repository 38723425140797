import React from 'react';
import PropTypes from 'prop-types';
import BlockContent from '@sanity/block-content-to-react';
import { getClient } from '../lib/sanity.server';
import * as Sentry from '@sentry/browser';
import Figure from './Figure';
import getYouTubeId from 'get-youtube-id';
import YouTube from 'react-youtube';
import Faqs from './faqs';
import Gallery from './gallery';
import ImageSerializer from './imageSerializer';

const { projectId, dataset } = getClient().config();

const SimpleBlockContent = (props) => {
  const { blocks, isApp = false, className = '' } = props;
  const serializers = {
    list: (props) => {
      return (
        <div className="">
          {(props.type === 'bullet' && <ul>{props.children}</ul>) || (
            <ol>{props.children}</ol>
          )}
        </div>
      );
    },
    listItem: (props) => {
      if (props.node.listItem === 'bullet') {
        return (
          <li
            className={`${className} list-inside list-disc relative w-full py-1`}
          >
            {props.children}
          </li>
        );
      } else {
        return (
          <li className={`${className} list-inside list-decimal`}>
            {props.children}
          </li>
        );
      }
    },
    types: {
      block: (props) => {
        const { style = 'normal' } = props.node;
        if (/^h\d/.test(style)) {
          const level = style.replace(/[^\d]/g, '');
          return React.createElement(
            style,
            { className: `heading-${level} ${className}` },
            props.children
          );
        }

        if (style === 'blockquote') {
          return <blockquote>- {props.children}</blockquote>;
        } else if (style === 'montserrat' || style === 'normal') {
          return <p className={`${className} my-3`}>{props.children}</p>;
        } else if (style === 'factBox') {
          return (
            <div className="flex max-w-xs font-date bg-peach-pale justify-start py-4 pl-2">
              <p className={`${className} pl-4`}>{props.children}</p>
            </div>
          );
        }
        return React.createElement(props.node.style, {
          ...props,
          className: 'font-objektiv',
        });
      },
      summary: (props) => {
        return (
          <p className={`${className} mt-3 text-base text-gray-500`}>
            {props.children}
          </p>
        );
      },
      figure: Figure,
      image: Figure,
      faqs: ({ node }) => {
        const { faqsarray } = node;
        return <Faqs faqs={faqsarray} />;
      },
      youtube: ({ node }) => {
        const { url } = node;
        const id = getYouTubeId(url);
        return (
          <YouTube
            containerClassName="relative h-96 w-full flex items-center justify-center"
            className={`${className} w-full h-96 max-w-md`}
            videoId={id}
          />
        );
      },
      gallery: ({ node }) => {
        //https://www.npmjs.com/package/react-responsive-carousel
        const { display, images, zoom } = node;
        return <Gallery images={images} />;
      },
    },
    marks: {
      centerAligned: (props) => {
        return (
          <div
            className={`${className} flex w-full items-center justify-center`}
          >
            <p>{props.children}</p>
          </div>
        );
      },
      link: (props) => {
        const { children, mark } = props;
        return (
          <a
            href={mark.href}
            className={`${className} underline cursor-pointer`}
          >
            {children}
          </a>
        );
      },
      color: (props) => {
        const { children, mark } = props;
        return (
          <span style={{ color: mark.hex }} className={className}>
            {children}
          </span>
        );
      },
    },
  };
  {
    /*----app----*/
  }
  const appSerializers = {
    list: (props) => {
      return (
        <div className="">
          {(props.type === 'bullet' && <ul>{props.children}</ul>) || (
            <ol>{props.children}</ol>
          )}
        </div>
      );
    },
    listItem: (props) => {
      if (props.node.listItem === 'bullet') {
        return (
          <li
            className={`${className} list-inside list-disc relative w-full py-1`}
          >
            {props.children}
          </li>
        );
      } else {
        return (
          <li className={`${className} "list-inside list-decimal`}>
            {props.children}
          </li>
        );
      }
    },
    types: {
      block: (props) => {
        const { style = 'normal' } = props.node;
        if (/^h\d/.test(style)) {
          const level = style.replace(/[^\d]/g, '');
          return React.createElement(
            style,
            { className: `heading-app-${level} ${className}` },
            props.children
          );
        }

        if (style === 'blockquote') {
          return <blockquote>- {props.children}</blockquote>;
        } else if (style === 'montserrat' || style === 'normal') {
          return (
            <p className={`${className} font-objektiv my-3`}>
              {props.children}
            </p>
          );
        } else if (style === 'factBox') {
          return (
            <div className="flex max-w-xs font-date bg-peach-pale justify-start py-4 pl-2">
              <p className={`${className} pl-4`}>{props.children}</p>
            </div>
          );
        }
        return React.createElement(props.node.style, {
          ...props,
          className: 'font-objektiv',
        });
      },
      summary: (props) => {
        return (
          <p className={`${className} mt-3 text-base text-gray-500`}>
            {props.children}
          </p>
        );
      },
      figure: Figure,
      image: ImageSerializer,
      faqs: ({ node }) => {
        const { faqsarray } = node;
        return <Faqs faqs={faqsarray} />;
      },
      youtube: ({ node }) => {
        const { url } = node;
        const id = getYouTubeId(url);
        <div className=""></div>;
        return (
          <YouTube
            containerClassName="relative h-96 w-full flex items-center justify-center"
            className={`${className} w-full h-96 max-w-md`}
            videoId={id}
          />
        );
      },
      gallery: ({ node }) => {
        //https://www.npmjs.com/package/react-responsive-carousel
        const { display, images, zoom } = node;
        return <Gallery images={images} />;
      },
    },
    marks: {
      centerAligned: (props) => {
        return (
          <div
            className={`${className} flex w-full items-center justify-center`}
          >
            <p>{props.children}</p>
          </div>
        );
      },
      link: (props) => {
        const { children, mark } = props;
        return (
          <a
            href={mark.href}
            className={`${className} underline cursor-pointer`}
          >
            {children}
          </a>
        );
      },
      color: (props) => {
        const { children, mark } = props;
        return (
          <span style={{ color: mark.hex }} className={className}>
            {children}
          </span>
        );
      },
    },
  };

  if (!blocks) {
    Sentry.captureException(
      new Error(
        `Error in SimpleBlockContent: Expected blocks but no blocks present.`
      )
    );
    return null;
  }

  return (
    <BlockContent
      blocks={blocks}
      serializers={isApp ? appSerializers : serializers}
      projectId={projectId}
      dataset={dataset}
      imageOptions={{ w: 600, h: 600, fit: 'max' }}
    />
  );
};

SimpleBlockContent.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
  node: PropTypes.any,
  children: PropTypes.any,
};

export default SimpleBlockContent;
