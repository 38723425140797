import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import React from 'react';
import Image from 'next/image';
import { urlFor } from '../lib/sanity';

const Gallery = ({ images }) => {
  return (
    <Carousel showThumbs={false}>
      {images.map((image, index) => {
        return (
          <div key={index}>
            <Image src={urlFor(image).url()} alt={image.alt} layout='fill' quality={40} />
            {image.alt && <p className="legend">{image.alt}</p>}
          </div>
        );
      })}
    </Carousel>
  );
};
export default Gallery;
