import {
  createPreviewSubscriptionHook,
  createImageUrlBuilder,
} from 'next-sanity';
import { config } from './config';

const newDatasetConfig = {
  ...config,
  dataset: 'lildog-new',
};

// Set up the live preview subscription hook
export const usePreviewSubscription = createPreviewSubscriptionHook(config);

export const urlFor = (source, useNewDataset = true) =>
  useNewDataset
    ? createImageUrlBuilder(newDatasetConfig).image(source)
    : createImageUrlBuilder(config).image(source);
