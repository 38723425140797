import React from 'react';
import PropTypes from 'prop-types';
import imageUrlBuilder from '@sanity/image-url';
import { getClient } from '../lib/sanity.server';
import Image from 'next/image';
import { getResponsiveBackgroundImageWidth } from '@lilbit/shared';
import { CameraIcon } from '@heroicons/react/24/outline';


const builder = imageUrlBuilder(getClient());

const Figure = ({ node }) => {
  const { alt, caption, asset } = node;
  let backgroundImageWidth = getResponsiveBackgroundImageWidth();
  if (!asset) {
    return undefined;
  }
  return (
    <figure className="font-objektiv mb-8">
      <Image
        src={builder.image(asset).auto('format').url()}
        width={backgroundImageWidth}
        height={500}
        objectFit="cover"
        quality={40}
        alt={alt}
        title={alt}
      />
      {caption && (
        <figcaption>
          <div className="flex">
            <div>
              <CameraIcon className="h-5 w-5 text-gray-400" />
            </div>
            <div className="text-gray-500 italic pl-4 text-p-md lg:text-p-sm-desktop">
              <p>{caption}</p>
            </div>
          </div>
        </figcaption>
      )}
    </figure>
  );
};

Figure.propTypes = {
  node: PropTypes.shape({
    alt: PropTypes.string,
    caption: PropTypes.string,
    asset: PropTypes.shape({
      _ref: PropTypes.string,
    }),
  }),
};
export default Figure;
